import React from "react";
import serviceS1 from "@/images/marketing.jpg";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>Digital Marketing</h2>
      <p>
        At Solxk, we excel in facilitating businesses to thrive in the
        digital realm. Our digital marketing professionals employ data-driven
        strategies, innovative content, and advanced technology to foster
        growth, elevate brand visibility, and engage your target audience. We
        contend that successful digital marketing transcends mere audience
        reach; it is about forging meaningful connections that prompt action.
        Whether through search engine optimization (SEO), social media
        marketing, or email campaigns, we tailor our approach to align with your
        specific business goals and objectives.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>planning & strategy</h3>
          <p>Services We Offer:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Search Engine Optimization
              (SEO)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Pay-Per-Click (PPC)
              Advertising
            </li>
            <li>
              <i className="fa fa-check-square"></i>Pay-Per-Click (PPC)
              Advertising
            </li>
            <li>
              <i className="fa fa-check-square"></i>Content Marketing &
              Analytics and Reporting
            </li>
            <li>
              <i className="fa fa-check-square"></i>Email Marketing
            </li>
          </ul>
        </div>
      </div>
      <p>
        Whether you are a startup seeking to establish a strong brand identity
        or an established business aiming to refresh your visual presence,
        Solxk is here to assist you. Contact us today to discuss your design
        needs and take the first step towards transforming your brand into a
        visual masterpiece.
      </p>
      <p>
        At Solxk, we recognize that the foundation of a successful web
        development project lies in meticulous planning. Before writing a single
        line of code or designing a single pixel, we invest time in
        understanding your business goals, target audience, and unique
        requirements. Our comprehensive planning process ensures that every
        aspect of your project is carefully considered and executed with
        precision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
